export const blog = () => {
  var swiper = new Swiper(".mySwipers", {
    slidesPerView: 3,
    spaceBetween: 35,
    speed: 500,
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        centeredSlides: true,
      },
      1024: {
        slidesPerView: 2,
        centeredSlides: true,
      },
    },
  });
  var swiper = new Swiper(".mySwip", {
    slidesPerView: 1.1,
    spaceBetween: 10,
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
};
