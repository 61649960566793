export const custom_slider = () => {
  const swiperContainer = document.querySelector(".swiper-containers");

  if (!swiperContainer) {
    return;
  }

  const slides = swiperContainer.querySelectorAll(".swiper-slide");
  const categorySlider = document.querySelector(".category__slider");

  let loop = true;

  if (slides.length <= 2) {
    loop = false;
    if (categorySlider) {
      categorySlider.classList.add("no-loop");
    }
  } else {
    if (categorySlider) {
      categorySlider.classList.remove("no-loop");
    }
  }

  const swiper = new Swiper(".swiper-containers", {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true,
    loop: loop,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  const hotspots = document.querySelectorAll(".custom-slider__hotspot");
  hotspots.forEach((hotspot) => {
    hotspot.addEventListener("click", function (event) {
      event.stopPropagation();

      const infoBlock = this.nextElementSibling;

      if (infoBlock) {
        if (infoBlock.style.display === "block") {
          infoBlock.style.display = "none";
        } else {
          document
            .querySelectorAll(".custom-slider__products")
            .forEach((info) => {
              info.style.display = "none";
            });
          infoBlock.style.display = "block";
        }
      }
    });
  });

  document.addEventListener("click", function () {
    document.querySelectorAll(".custom-slider__products").forEach((info) => {
      info.style.display = "none";
    });
  });

  document.querySelectorAll(".custom-slider__products").forEach((products) => {
    products.addEventListener("click", function (event) {
      event.stopPropagation();
    });
  });
};
