export const cookie = () => {
  const loadCookieScript = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.src =
      "//cdn.cookie-script.com/s/01f645f07d848462c24a1a58e662c38c.js";
    document.body.appendChild(script);
  };

  window.addEventListener(
    "scroll",
    function () {
      if (!this.cookieScriptLoaded) {
        loadCookieScript();
        this.cookieScriptLoaded = true;
      }
    },
    { once: true }
  );
};
