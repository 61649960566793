export const popup = () => {
  const openPopupButton = document.getElementById("openPopup");
  const popup = document.getElementById("popup");
  const closePopupButton = document.getElementById("popupClose");

  if (openPopupButton && popup && closePopupButton) {
    const pdfURL = openPopupButton.getAttribute("data-pdf-url");

    openPopupButton.addEventListener("click", function (e) {
      e.preventDefault();
      popup.classList.add("open");
    });

    closePopupButton.addEventListener("click", function () {
      popup.classList.remove("open");
    });

    document.addEventListener(
      "wpcf7mailsent",
      function (event) {
        popup.classList.remove("open");
        window.open(pdfURL, "_blank");
      },
      false
    );
  }
};
