export const sliders = () => {
  var swiper = new Swiper(".feature", {
    slidesPerView: 1,
    spaceBetween: 15,
    autoplay: {
      delay: 5000,
    },
    loop: true,
    breakpoints: {
      428: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
    },
  });
};
