export const modal = () => {
  const forms = document.querySelectorAll(".wpcf7");
  const header = document.querySelector("#header");

  forms.forEach((form) => {
    let formWrapper = document.getElementById("kontakt");

    if (!formWrapper) {
      console.log("Обгортка з id 'kontakt' не знайдена. Код не виконується.");
      return;
    }

    form.addEventListener(
      "wpcf7mailsent",
      function (event) {
        let wrapper = formWrapper.querySelector(".contact-form-wrapper");
        let thanks = document.querySelector(".contact-form-thanks");

        console.log("wrapper:", wrapper);
        console.log("thanks:", thanks);

        if (wrapper && thanks) {
          let headerHeight = header ? header.offsetHeight : 0;
          let top = formWrapper.offsetTop - headerHeight - 10;

          wrapper.classList.add("hide");
          thanks.classList.add("show");
          setTimeout(() => {
            wrapper.classList.remove("hide");
            thanks.classList.remove("show");
          }, 6000);
        }
      },
      false
    );
  });
};
