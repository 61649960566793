export const menu = () => {
  if (window.matchMedia("(max-width: 768px)").matches) {
    var titles = document.querySelectorAll(".menu.title");
    titles.forEach(function (title) {
      title.addEventListener("click", function () {
        var item = this.closest(".item");
        var isActive = item.classList.contains("active");
        document
          .querySelectorAll(".footer__content__menu__link .item")
          .forEach(function (i) {
            i.classList.remove("active");
          });
        if (!isActive) {
          item.classList.add("active");
        }
      });
    });
    var firstItem = document.querySelector(
      ".footer__content__menu__link .item"
    );
    if (firstItem) {
      firstItem.classList.add("active");
    }
  }
};
