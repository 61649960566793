export const tabs = () => {
  var tabTitles = document.querySelectorAll(".tabs__title");
  var tabContents = document.querySelectorAll(
    ".hero_product-info-tabs-content"
  );

  tabTitles.forEach(function (title) {
    title.addEventListener("click", function () {
      var target = this.getAttribute("data-tab");

      tabTitles.forEach(function (item) {
        item.classList.remove("active");
      });

      tabContents.forEach(function (content) {
        content.classList.remove("active");
      });

      this.classList.add("active");
      document.getElementById(target).classList.add("active");
    });
  });
};
