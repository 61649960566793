export const position = () => {
  const initToggleButtons = () => {
    const toggleButtons = document.querySelectorAll(".toggle-details");
    if (toggleButtons.length > 0) {
      const firstButton = toggleButtons[0];
      const firstItemText = firstButton
        .closest(".position__information-item")
        .querySelector(".position__information-item-text");
      firstItemText.classList.add("show");
      firstButton.textContent = "Hide Details";
    }
    toggleButtons.forEach((button) => {
      const newButton = button.cloneNode(true);
      button.parentNode.replaceChild(newButton, button);
    });
    document.querySelectorAll(".toggle-details").forEach((button) => {
      button.addEventListener("click", function () {
        const itemText = this.closest(
          ".position__information-item"
        ).querySelector(".position__information-item-text");
        const isActive = itemText.classList.contains("show");
        document
          .querySelectorAll(".position__information-item-text")
          .forEach((text) => {
            text.classList.remove("show");
          });
        document.querySelectorAll(".toggle-details").forEach((btn) => {
          btn.textContent = "More details";
        });

        if (!isActive) {
          itemText.classList.add("show");
          this.textContent = "Hide Details";
        } else {
          itemText.classList.remove("show");
          this.textContent = "More details";
        }
      });
    });
  };
  initToggleButtons();

  document.addEventListener("facetwp-loaded", function () {
    initToggleButtons();
  });
};
