export const faq = () => {
  $(".faq__list-number").on("click", function () {
    const toggleId = $(this).attr("data-toggle");
    const answerElement = $(`#${toggleId}`);
    const isActive = answerElement.hasClass("active");

    $(".faq__list-answer").removeClass("active").slideUp();
    $(".faq__list-toggler").removeClass("active");

    if (!isActive) {
      answerElement.addClass("active").slideDown();
      $(this).find(".faq__list-toggler").addClass("active");
    }
  });
};
