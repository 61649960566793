export const custom_menu = () => {
  jQuery(document).ready(function ($) {
    $(".menu-items").hover(function () {
      if ($(this).find(".menu-items-menu__content").length > 0) {
        $(".menu-items-menu__content").removeClass("active");
        $(this).find(".menu-items-menu__content").addClass("active");
        $(".header").addClass("active");
        $(this).addClass("active");
        $("body").attr("data-lenis-prevent", "").addClass("ov-hidden");
      } else {
        $(".menu-items-menu__content").removeClass("active");
        $(".menu-items").removeClass("active");
        $(".header").removeClass("active");
        $("body").removeAttr("data-lenis-prevent").removeClass("ov-hidden");
      }
    });

    $(".header").on("mouseleave", function () {
      $(".menu-items-menu__content").removeClass("active");
      $(".menu-items").removeClass("active");
      $(".header").removeClass("active");
      $("body").removeAttr("data-lenis-prevent").removeClass("ov-hidden");
    });

    $(".search").click(function () {
      $(".menu-items-menu__content").removeClass("active");
      $(".menu-items").removeClass("active");
      $(".header").removeClass("active");
      $("body").removeAttr("data-lenis-prevent").removeClass("ov-hidden");
    });

    $(".menu_mobile_item").click(function () {
      var parentLi = $(this);
      if (parentLi.hasClass("active")) {
        parentLi.removeClass("active");
        $("body").removeAttr("data-lenis-prevent").removeClass("ov-hidden");
      } else {
        $(".menu_mobile_item").removeClass("active");
        parentLi.addClass("active");
        $("body").attr("data-lenis-prevent", "").addClass("ov-hidden");
      }
    });

    $(".menu_mobile_item .menu_icon").click(function (e) {
      e.preventDefault();
      e.stopPropagation();

      var parentLi = $(this).closest(".menu_mobile_item");
      var subMenu = parentLi.children(".sub-menu");
      var icon = $(this);

      if (subMenu.hasClass("active")) {
        subMenu.removeClass("active");
        icon.removeClass("active");
      } else {
        subMenu.addClass("active");
        icon.addClass("active");
      }
    });
  });
};
