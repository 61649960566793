export const burger = () => {
  jQuery(document).ready(function ($) {
    $(document).on("click", ".menu-trigger", function () {
      $(this).toggleClass("active");
      $(".mobile-menu").toggleClass("active");
      $(".header").toggleClass("menu-opened");
      $("body").toggleClass("ov-hidden", $(this).hasClass("active"));
      $(document).find(".mobile-menu__sub").removeClass("active");

      if ($(this).hasClass("active")) {
        $("body").attr("data-lenis-prevent", "");
        lenis.destroy();
      } else {
        $("body").removeAttr("data-lenis-prevent");
        startLenis();
      }
    });

    $(document).on("click", ".mobile-menu__main", function () {
      $(this).next(".mobile-menu__sub").addClass("active");
    });

    $(document).on("click", ".mobile-menu__back", function () {
      $(this).closest(".mobile-menu__sub").removeClass("active");
    });
  });
};
