export const accordion = () => {
  const accordions = document.querySelectorAll(".accordion__info");

  accordions.forEach((accordion, index) => {
    const header = accordion.querySelector(".accordion__header");
    const content = accordion.querySelector(".accordion__content");

    header.addEventListener("click", () => {
      const isOpen = content.classList.contains("active");

      accordions.forEach((a, i) => {
        const h = a.querySelector(".accordion__header");
        const c = a.querySelector(".accordion__content");
        if (i === index) {
          c.style.height = isOpen ? "0px" : `${c.scrollHeight}px`;
          c.classList.toggle("active");
          h.classList.toggle("active");
        } else {
          c.style.height = "0px";
          c.classList.remove("active");
          h.classList.remove("active");
        }
      });
    });
  });
};
