export const photo = () => {
  document
    .querySelectorAll(".product__information__list__item")
    .forEach((item) => {
      item.addEventListener("mouseenter", () => {
        document
          .querySelectorAll(".product__information__list__item")
          .forEach((otherItem) => {
            if (otherItem !== item) {
              otherItem.classList.add("full");
            }
          });
      });

      item.addEventListener("mouseleave", () => {
        document
          .querySelectorAll(".product__information__list__item")
          .forEach((otherItem) => {
            otherItem.classList.remove("full");
          });
      });
    });
};
