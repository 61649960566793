export const features = () => {
  if (!document.body.classList.contains("tax-category-application")) {
    return;
  }

  const progressBar = document.querySelector(".features-progress-bar");
  const wraps = document.querySelectorAll(".features__item");
  let currentActive = 0;

  function updateProgressBar() {
    const progressWidth = ((currentActive + 1) / wraps.length) * 100;
    progressBar.style.setProperty("--progress-width", `${progressWidth}%`);

    wraps.forEach((wrap, index) => {
      if (index <= currentActive) {
        wrap.classList.add("active");
      } else {
        wrap.classList.remove("active");
      }
    });
  }

  function autoProgress() {
    currentActive++;
    if (currentActive >= wraps.length) {
      currentActive = wraps.length - 1;
    }

    updateProgressBar();
    if (currentActive < wraps.length - 1) {
      setTimeout(autoProgress, 3000);
    }
  }

  wraps[0].classList.add("active");
  updateProgressBar();

  const section = document.querySelector(".features");

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(autoProgress, 3000);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      threshold: 0.5,
    }
  );
  observer.observe(section);
};
