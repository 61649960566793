export const tab = () => {
  const panels = document.querySelectorAll(".at-accordion__panel");
  const nextButtons = document.querySelectorAll(".next_button");

  panels.forEach((panel) => {
    panel.addEventListener("click", function () {
      console.log("Panel clicked");
      const isOpen = panel.classList.contains("is-open");
      panels.forEach((p) => p.classList.remove("is-open"));
      if (!isOpen) {
        panel.classList.add("is-open");
      }
    });
  });

  nextButtons.forEach((button) => {
    if (!button.dataset.listener) {
      button.dataset.listener = "true";
      button.addEventListener("click", function (event) {
        event.stopPropagation();

        const currentPanel = event.target.closest(".at-accordion__panel");
        const allPanels = Array.from(
          document.querySelectorAll(".at-accordion__panel")
        );
        const currentIndex = allPanels.indexOf(currentPanel);
        const nextIndex = (currentIndex + 1) % allPanels.length;
        const nextPanel = allPanels[nextIndex];

        panels.forEach((p) => p.classList.remove("is-open"));
        nextPanel.classList.add("is-open");
      });
    }
  });
};
