export const gallery_slider = () => {
  if (document.body.classList.contains("single-product")) {
    var galleryThumbs = new Swiper(".gallery-thumbs.custom-swiper-container", {
      centeredSlides: true,
      centeredSlidesBounds: true,
      slidesPerView: "auto",
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      direction: "vertical",
    });

    var galleryMain = new Swiper(".gallery-main.custom-swiper-container", {
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      preventInteractionOnTransition: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      thumbs: {
        swiper: galleryThumbs,
      },
    });

    galleryMain.on("slideChangeTransitionStart", function () {
      galleryThumbs.slideTo(galleryMain.activeIndex);
    });

    galleryThumbs.on("transitionStart", function () {
      galleryMain.slideTo(galleryThumbs.activeIndex);
    });
  }
};
