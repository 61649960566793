export const search = () => {
  // PC
  const searchIconPc = document.querySelector(".search-icon-pc");
  const searchPopupPc = document.getElementById("search-popup-pc");
  const closeBtnPc = document.querySelector(".close-btn-pc");

  if (searchIconPc && searchPopupPc && closeBtnPc) {
    searchIconPc.addEventListener("click", function () {
      searchPopupPc.classList.add("show");
      searchPopupPc.style.animation = "fadeIn 0.5s ease-in-out forwards";
      document.body.classList.add("active");
    });

    closeBtnPc.addEventListener("click", function () {
      searchPopupPc.style.animation = "fadeOut 0.5s ease-in-out forwards";
      setTimeout(() => {
        searchPopupPc.classList.remove("show");
        searchPopupPc.style.animation = "";
        document.body.classList.remove("active");
      }, 500);
    });

    window.addEventListener("click", function (event) {
      if (event.target == searchPopupPc) {
        searchPopupPc.style.animation = "fadeOut 0.5s ease-in-out forwards";
        setTimeout(() => {
          searchPopupPc.classList.remove("show");
          searchPopupPc.style.animation = "";
          document.body.classList.remove("active");
        }, 500);
      }
    });
  }

  // mob
  const searchIconMob = document.querySelector(".search-icon-mob");
  const searchPopupMob = document.getElementById("search-popup-mob");
  const closeBtnMob = document.querySelector(".close-btn-mob");

  if (searchIconMob && searchPopupMob && closeBtnMob) {
    searchIconMob.addEventListener("click", function () {
      searchPopupMob.classList.add("show");
      searchPopupMob.style.animation = "fadeIn 0.5s ease-in-out forwards";
      document.body.classList.add("active");
    });

    closeBtnMob.addEventListener("click", function () {
      searchPopupMob.style.animation = "fadeOut 0.5s ease-in-out forwards";
      setTimeout(() => {
        searchPopupMob.classList.remove("show");
        searchPopupMob.style.animation = "";
        document.body.classList.remove("active");
      }, 500);
    });

    window.addEventListener("click", function (event) {
      if (event.target == searchPopupMob) {
        searchPopupMob.style.animation = "fadeOut 0.5s ease-in-out forwards";
        setTimeout(() => {
          searchPopupMob.classList.remove("show");
          searchPopupMob.style.animation = "";
          document.body.classList.remove("active");
        }, 500);
      }
    });
  }
};
