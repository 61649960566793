export const tab_systems = () => {
  const tabItems = document.querySelectorAll(".tab_systems__tabs-item");
  const tabContents = document.querySelectorAll(".tab_systems__content-item");

  tabItems.forEach((tab, index) => {
    tab.addEventListener("click", function () {
      tabItems.forEach((item) => item.classList.remove("active"));
      tabContents.forEach((content) => content.classList.remove("active"));
      tab.classList.add("active");
      document.getElementById(`tab-${index}`).classList.add("active");
    });
  });
};
