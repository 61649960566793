export const scroll = () => {
  var header = document.getElementById("header");

  window.addEventListener("scroll", function () {
    if (window.scrollY > 0) {
      header.classList.add("scroll");
    } else {
      header.classList.remove("scroll");
    }
  });
};
