export const products = () => {
  $(".products__list-answer").first().addClass("active").slideDown();
  $(".products__list-number")
    .first()
    .find(".products__list-toggler")
    .addClass("active");

  $(".products__list-number").on("click", function () {
    const toggleId = $(this).attr("data-toggle");
    const answerElement = $(`#${toggleId}`);
    const isActive = answerElement.hasClass("active");
    $(".products__list-answer").removeClass("active").slideUp();
    $(".products__list-toggler").removeClass("active");
    if (!isActive) {
      answerElement.addClass("active").slideDown();
      $(this).find(".products__list-toggler").addClass("active");
    }
  });
};
