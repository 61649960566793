export const phone = () => {
  if (window.location.pathname.includes("contact")) {
    var input = document.querySelector("#phone-number");
    if (input && typeof window.intlTelInput === "function") {
      var iti = window.intlTelInput(input, {
        initialCountry: "us",
        separateDialCode: true,
      });
    }
  }
};
