export const split_text = () => {
  const text = new SplitType(".split-text", { types: "words, chars" });
  gsap.registerPlugin(ScrollTrigger);

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".history",
      start: "top center",
      end: "bottom center",
      scrub: 1,
      markers: false,
    },
  });

  tl.fromTo(
    ".split-text .char",
    { opacity: 0.4 },
    { opacity: 1, duration: 0.5, stagger: 0.02 }
  );

  const lenis = new Lenis();

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);
};
