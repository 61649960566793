export const number = () => {
  const numbers = document.querySelectorAll(
    ".culture__information-text-list-info-number"
  );

  function animateNumber(element, start, end, duration) {
    let startTime = null;

    function step(currentTime) {
      if (!startTime) startTime = currentTime;
      const progress = currentTime - startTime;
      const currentNumber = Math.min(
        Math.floor((progress / duration) * (end - start) + start),
        end
      );
      element.textContent = currentNumber;
      if (currentNumber < end) {
        requestAnimationFrame(step);
      }
    }

    requestAnimationFrame(step);
  }

  function handleIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        numbers.forEach((numberElement) => {
          const endValue = parseInt(numberElement.textContent, 10);
          animateNumber(numberElement, 0, endValue, 2000);
        });
        observer.unobserve(entry.target);
      }
    });
  }

  const observer = new IntersectionObserver(handleIntersection, {
    threshold: 0.5,
  });

  const target = document.querySelector(".culture__information");
  if (target) {
    observer.observe(target);
  }
};
