export const copy = () => {
  function copyToClipboard(url) {
    navigator.clipboard.writeText(url).then(
      function () {
        alert("URL copied to clipboard!");
      },
      function (err) {
        console.error("Copy error: ", err);
      }
    );
  }
  const copyButton = document.querySelector(".copy-url-button");
  if (copyButton) {
    copyButton.addEventListener("click", function () {
      const url = window.location.href;
      copyToClipboard(url);
    });
  }
};
