export const piling_solutions = () => {
  const swiperContainer = document.querySelector(
    ".swiper-container-description"
  );
  const tabItems = document.querySelectorAll(
    ".piling_solutions__content__item"
  );

  if (!swiperContainer || !tabItems.length) {
    return;
  }

  const swiper = new Swiper(swiperContainer, {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (_index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    loop: true,
    watchSlidesProgress: true,
  });

  swiperContainer.addEventListener("mouseenter", () => {
    swiper.autoplay.stop();
    swiperContainer.classList.add("swiper-paused");
  });

  swiperContainer.addEventListener("mouseleave", () => {
    swiper.autoplay.start();
    swiperContainer.classList.remove("swiper-paused");
  });

  tabItems.forEach((tab, index) => {
    tab.addEventListener("click", () => {
      swiper.slideToLoop(index);
    });
  });

  swiper.on("slideChange", () => {
    const activeIndex = swiper.realIndex;
    tabItems.forEach((tab, index) => {
      if (index === activeIndex) {
        tab.classList.add("active");
      } else {
        tab.classList.remove("active");
      }
    });
  });

  if (tabItems.length > 0) {
    tabItems[0].classList.add("active");
  }
};
