/** Scroll product color */

export const product_bg = () => {
  document.addEventListener("scroll", function () {
    const productSection = document.querySelector(".product");
    if (!productSection) {
      return;
    }

    const sectionTop = productSection.offsetTop;
    const sectionHeight = productSection.offsetHeight;
    const scrollPosition = window.scrollY + window.innerHeight;
    let visibilityPercentage = (scrollPosition - sectionTop) / sectionHeight;
    visibilityPercentage = Math.min(Math.max(visibilityPercentage, 0), 1);
    const startColor = [180, 180, 180];
    const endColor = [0, 84, 165];
    const currentColor = startColor.map((startComponent, index) => {
      return Math.round(
        startComponent +
          (endColor[index] - startComponent) * visibilityPercentage
      );
    });

    productSection.style.backgroundColor = `rgb(${currentColor.join(",")})`;
  });
};
