export const selects = () => {
  if (document.body.classList.contains("single-product")) {
    const tabs = document.querySelectorAll(".tab-button");
    const contents = document.querySelectorAll(".tab-content");

    tabs.forEach((tab) => {
      tab.addEventListener("click", function () {
        const target = this.getAttribute("data-tab");

        contents.forEach((content) => {
          if (content.id === target) {
            content.style.display = "flex";
          } else {
            content.style.display = "none";
          }
        });

        tabs.forEach((t) => {
          t.classList.remove("active");
        });

        this.classList.add("active");
      });
    });

    if (tabs.length > 0) {
      tabs[0].classList.add("active");
    }
  }
};
