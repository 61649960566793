export const gallery = () => {
  var swiper = new Swiper(".gallery", {
    slidesPerView: 1,
    spaceBetween: 15,
    autoplay: {
      delay: 5000,
    },
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
      },
      769: {
        slidesPerView: 3,
      },
    },
  });
};
